import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";


const initialState = {
  cartState: false,
  cartItems: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [], // Let Suppose Database
  cartTotalAmount: 0,
  cartTotalQantity: 0,
  currency: 'USD',
  exchangeRates: {},
};

export const fetchExchangeRates = createAsyncThunk(
  "cart/fetchExchangeRates",
  async () => {
    try {
      const response = await fetch("https://api.exchangerate-api.com/v4/latest/USD");
      const data = await response.json();
      return data.rates; // Return the exchange rates
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      return {}; // Return empty object on error
    }
  }
);


// Define conversion rates
const conversionRates = {
  NGN: 1,
  USD: 1677.9,
  EUR: 1782.5,
  GBP: 2132.2
};

// Helper function to convert prices between currencies
const convertPrice = (amount, fromCurrency, toCurrency) => {
  // Convert to NGN first (base currency)
  const amountInNGN = fromCurrency === 'NGN' 
    ? amount 
    : amount * conversionRates[fromCurrency];
  
  // Then convert to target currency
  return toCurrency === 'NGN' 
    ? amountInNGN 
    : amountInNGN / conversionRates[toCurrency];
};

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },

    extraReducers: (builder) => {
      builder.addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.rates = action.payload;
      });
    },

    setOpenCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },

    setCloseCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },

    extraReducers: (builder) => {
      builder.addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.exchangeRates = action.payload;
      });
    },

    // Add new currency-related reducers
    setCartCurrency: (state, action) => {
      state.currency = action.payload;
    },

    // setExchangeRates: (state, action) => {
    //   state.exchangeRates = action.payload;
    // },
    
    

    // Update setAddItemToCart to handle currency
    setAddItemToCart: (state, action) => {
      const newItem = action.payload;
      const { id, currency: newCurrency } = newItem;
      const existingItem = state.cartItems.find(item => item.id === id);

      if (existingItem) {
        existingItem.cartQuantity += newItem.cartQuantity || 1;
      } else {
        state.cartItems.push({ ...newItem, cartQuantity: 1 });
      }

      // Update all cart items to the new currency
      state.cartItems = state.cartItems.map(item => {
        const convertedPrice = convertPrice(
          item.price,
          item.currency || state.currency,
          newCurrency,
          state.exchangeRates
        );
        return {
          ...item,
          price: parseFloat(convertedPrice),
          currency: newCurrency,
        };
      });

      state.currency = newCurrency;

      // Save updated cart to localStorage
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
      toast.success(`${newItem.title} added to Cart`);
    },

    setRemoveItemFromCart: (state, action) => {
      const removeItem = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );

      state.cartItems = removeItem;
      localStorage.setItem("cart", JSON.stringify(state.cartItems));

      toast.success(`${action.payload.title} Removed From Cart`);
    },

    setIncreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;

        toast.success(`Item QTY Increased`);
      }
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    setDecreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.success(`Item QTY Decreased`);
      }
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    setClearCartItems: (state, action) => {
      state.cartItems = [];
      toast.success(`Cart Cleared`);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },


    setGetTotals: (state, action) => {
      let { totalAmount, totalQTY } = state.cartItems.reduce((cartTotal, cartItem)=> {
        const { price, cartQuantity } = cartItem;
        const totalPrice = price * cartQuantity;

        cartTotal.totalAmount += totalPrice;
        cartTotal.totalQTY += cartQuantity;

        return cartTotal;
      }, {
        totalAmount: 0,
        totalQTY: 0,
      });

      state.cartTotalAmount = totalAmount;
      state.cartTotalQantity = totalQTY;
    },
  },
});

export const {
  setOpenCart,
  setCloseCart,
  setAddItemToCart,
  setRemoveItemFromCart,
  setIncreaseItemQTY,
  setDecreaseItemQTY,
  setClearCartItems,
  setExchangeRates,
  setGetTotals,
  setCurrency,
} = CartSlice.actions;

export const selectCartState = (state) => state.cart.cartState;
// export const { setCurrency } = cartSlice.actions;
export const selectCartItems = (state) => state.cart.cartItems;
export const selectTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectTotalQTY = (state) => state.cart.cartTotalQantity;
export const selectExchangeRates = (state) => state.cart.exchangeRates;
export const selectCurrency = (state) => state.cart.currency;

export default CartSlice.reducer;