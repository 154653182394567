import React, { useState, useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import slideshow1 from '../../assets/exhibition/slideshow/5.jpg'
import slideshow6 from '../../assets/exhibition/slideshow/6.jpg'
import slideshow15 from '../../assets/exhibition/slideshow/15.jpg'

import image0 from '../../assets/exhibition/0.jpg'
import image1 from '../../assets/exhibition/1.jpg'
import image2 from '../../assets/exhibition/2.jpg'
import image3 from '../../assets/exhibition/3.jpg'
import image4 from '../../assets/exhibition/4.jpg'
import image5 from '../../assets/exhibition/5.jpg'
import image6 from '../../assets/exhibition/6.jpg'
import image7 from '../../assets/exhibition/7.jpg'
import image8 from '../../assets/exhibition/8.jpg'
import image9 from '../../assets/exhibition/9.jpg'
import image10 from '../../assets/exhibition/10.jpg'
import image11 from '../../assets/exhibition/11.jpg'
import image12 from '../../assets/exhibition/12.jpg'
import image13 from '../../assets/exhibition/13.jpg'
import image14 from '../../assets/exhibition/14.jpg'
import image15 from '../../assets/exhibition/15.jpg'
import image16 from '../../assets/exhibition/16.jpg'
import image17 from '../../assets/exhibition/17.jpg'
import image18 from '../../assets/exhibition/18.jpg'
import image19 from '../../assets/exhibition/19.jpg'
import image20 from '../../assets/exhibition/20.jpg'
import image21 from '../../assets/exhibition/21.jpg'
import image22 from '../../assets/exhibition/22.jpg'
import image23 from '../../assets/exhibition/23.jpg'
import image24 from '../../assets/exhibition/24.jpg'
import image25 from '../../assets/exhibition/25.jpg'
import image26 from '../../assets/exhibition/26.jpg'
import image27 from '../../assets/exhibition/27.jpg'
import image28 from '../../assets/exhibition/28.jpg'
import image29 from '../../assets/exhibition/29.jpg'
import image30 from '../../assets/exhibition/30.jpg'
import image31 from '../../assets/exhibition/31.jpg'
import image32 from '../../assets/exhibition/32.jpg'
import image33 from '../../assets/exhibition/33.jpg'
import image34 from '../../assets/exhibition/34.jpg'
import image35 from '../../assets/exhibition/35.jpg'
import image36 from '../../assets/exhibition/36.jpg'
import image37 from '../../assets/exhibition/37.jpg'
import image39 from '../../assets/exhibition/39.jpg'
import image40 from '../../assets/exhibition/40.jpg'
import image41 from '../../assets/exhibition/41.jpg'
import image42 from '../../assets/exhibition/42.jpg'
import image43 from '../../assets/exhibition/43.jpg'
import image44 from '../../assets/exhibition/44.jpg'
import image45 from '../../assets/exhibition/45.jpg'
import image46 from '../../assets/exhibition/46.jpg'
import image47 from '../../assets/exhibition/47.jpg'
import image48 from '../../assets/exhibition/48.jpg'
import image49 from '../../assets/exhibition/49.jpg'
import image50 from '../../assets/exhibition/50.jpg'
import image51 from '../../assets/exhibition/51.jpg'
import arrowLeft from '../../assets/arrow-left.png'
import arrowRight from '../../assets/arrow-right.png'
import close from '../../assets/close.png'
import { Play, X } from 'lucide-react';

const images = [
  image0, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
  image11, image12, image13, image14, image15, image16, image17, image18, image19, image20,
  image21, image22, image23, image24, image25, image26, image27, image28, image29, image30,
  image31, image32, image33, image34, image35, image36, image37, image39, image40,
  image41, image42, image43, image44, image45, image46, image47, image48, image49, image50,
  image51
];

const InHerHands = () => {
	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const [columns, setColumns] = useState(4);
	const [imageColumns, setImageColumns] = useState([]);

	const [currentVideoUrl, setCurrentVideoUrl] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
  
  const videos = [
    {
      thumbnail: slideshow15,
      videoUrl: "https://player.vimeo.com/video/1041536456?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1",
      firstLine: "in her hands exhibition",
      secondLine: "Watch the Artist talk"
    },
    {
      thumbnail: slideshow1,
      videoUrl: "https://player.vimeo.com/video/1043299981?badge=0&autopause=0&player_id=0&app_id=58479/embed",
      firstLine: "in her hands exhibition",
      secondLine: "Watch the documentary film"
    },
    {
      thumbnail: slideshow6,
      videoUrl: "https://player.vimeo.com/video/1044069213?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1",
      firstLine: "in her hands exhibition",
      secondLine: "Watch the TV Feature"
    },
  ];

 	const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl('');
  };



	  // Responsive column adjustment
	useEffect(() => {
	    const handleResize = () => {
	      const screenWidth = window.innerWidth;
	      if (screenWidth < 640) setColumns(1);
	      else if (screenWidth < 768) setColumns(2);
	      else if (screenWidth < 1024) setColumns(3);
	      else setColumns(4);
		};

	    // Initial setup and add event listener
	    handleResize();
	    window.addEventListener("resize", handleResize);
	    return () => window.removeEventListener("resize", handleResize);
	  }, []);

	  // Distribute images across columns
	  useEffect(() => {
	    if (images.length === 0) return;

	    // Create empty columns
	    const newColumns = Array.from({ length: columns }, () => []);

	    // Distribute images across columns
	    images.forEach((image, index) => {
	      const columnIndex = index % columns;
	      newColumns[columnIndex].push({ src: image, index });
	    });

	    setImageColumns(newColumns);
	}, [columns]);

	const openLightbox = (index) => {
	    setCurrentImageIndex(index);
	    setLightboxOpen(true);
	};

	const closeLightbox = () => {
	    setLightboxOpen(false);
	    setCurrentImageIndex(0);
	};

	const showNextImage = () => {
	    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const showPrevImage = () => {
	    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
	};

	return (
		<>


			<div className="bg-[#252525] min-h-screen">
				<div className="xl:h-[400px] lg:h-[400px] md:h-[400px] h-[300px] 
					xl:px-20 xl:py-4 px-4 py-4">
					<Swiper 
						spaceBetween={30}
						pagination={{
		          clickable: true,
		        }} 
						centeredSlides={true}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						// navigation={true}
						modules={[Autoplay, Pagination, Navigation]}
						className="mySwiper">
				    
		      	{videos.map((video, index) => (
		          <SwiperSlide key={index}>
		            <div className="flex justify-center items-center xl:px-40 md:px-20 px-5">
		              <div className="relative w-full">
		                <div 
		                  className="relative cursor-pointer w-full xl:h-[500px] md:h-[500px] h-[400px] " 
		                  onClick={() => openModal(video.videoUrl)}
		                >
		                  <img 
		                    src={video.thumbnail}
		                    alt={`Video thumbnail ${index + 1}`} 
		                    className="absolute w-full h-full object-cover"
		                  />
		                  <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
		                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
		                      <Play size={64} className="text-white" />
		                    </div>
		                  </div>
		                  <div className="absolute bottom-12 right-4 text-white font-medium bg-black/50 px-3 py-1 rounded">
								        <p className="uppercase font-semibold xl:text-xl lg:text-xl md:text-xl text-lg tracking-[.12em]">{video.firstLine}</p>
								        <p className="uppercase xl:text-base lg:text-base md:text-base text-[14px] tracking-[.12em]">{video.secondLine}</p>
								      </div>
		                </div>
		              </div>
		            </div>
		          </SwiperSlide>
		        ))}
		      	
					</Swiper>
				</div>

					<div className="bg-[#252525] min-h-screen py-8 xl:px-[240px] lg:px-[240px] px-[30px]">
						<div className="flex gap-4">
						  	{imageColumns.map((column, colIndex) => (
						      <div key={colIndex} className="flex-1 flex flex-col gap-4">
						      {column.map((image, imgIndex) => (
						        <div key={imgIndex} className="w-full cursor-pointer" onClick={() => openLightbox(image.index)}>
						          <img
						          	src={image.src}
						          	alt={`Gallery ${image.index + 1}`}
						          	className="w-full object-cover shadow-md hover:shadow-xl transition-transform duration-300 hover:scale-105"
						          />
						        </div>
						      ))}
						     	</div>
						   	))}
						</div>
					</div>

					{/* Lightbox */}
		      {lightboxOpen && (
		        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
		          <div className="relative flex items-center justify-center w-full">
		            <button
		              onClick={showPrevImage}
		              className="absolute left-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
		            >
		              <img width="24" height="24" src={arrowLeft}/>
		            </button>

		            <img
		              src={images[currentImageIndex]}
		              alt={`Lightbox ${currentImageIndex + 1}`}
		              className="max-w-full max-h-screen"
		            />

		            <button
		              onClick={showNextImage}
		              className="absolute right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
		            >
		              <img width="24" height="24" src={arrowRight}/>
		            </button>

		            <button
		              onClick={closeLightbox}
		              className="absolute top-4 right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
		            >
		              <img width="24" height="24" src={close}/>
		            </button>
		          </div>
		        </div>
		      )}

			</div>



			{/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div 
            className="absolute inset-0 bg-black/90 backdrop-blur-sm"
            onClick={closeModal}
          />
          
          <div className="relative w-[95vw] h-[90vh] bg-black rounded-lg overflow-hidden">
            <button 
              onClick={closeModal}
              className="absolute right-4 top-4 z-50 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            >
              <X className="h-6 w-6 text-white" />
            </button>
            
            <iframe
              src={currentVideoUrl}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              className="w-full h-full"
              title="Video Content"
              allowFullScreen
            />
          </div>
        </div>
      )}
		</>
	)
}

export default InHerHands