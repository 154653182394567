import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const EditPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    author: '',
    date: '',
    slug: '',
    coverImage: '',
    content: []
  });
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`http://localhost:4000/post/${id}`);
        setFormData(response.data);
        if (response.data.coverImage) {
          setPreview(response.data.coverImage);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch post');
        setLoading(false);
      }
    };

    if (id) {
      fetchPost();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setFormData(prev => ({
          ...prev,
          coverImage: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextChange = (content, index) => {
    setFormData(prev => ({
      ...prev,
      content: prev.content.map((block, i) => 
        i === index && block.type === 'text' 
          ? { ...block, content }
          : block
      )
    }));
  };

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          content: prev.content.map((block, i) =>
            i === index && block.type === 'image'
              ? { ...block, src: reader.result }
              : block
          )
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const addContentBlock = (type) => {
    const newBlock = type === 'text' 
      ? { type: 'text', content: '' }
      : { type: 'image', src: '' };
    
    setFormData(prev => ({
      ...prev,
      content: [...prev.content, newBlock]
    }));
  };

  const removeContentBlock = (index) => {
    setFormData(prev => ({
      ...prev,
      content: prev.content.filter((_, i) => i !== index)
    }));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.content);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData(prev => ({
      ...prev,
      content: items
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(`http://localhost:4000/post/${id}`, formData);
      navigate('/posts');
    } catch (err) {
      setError('Failed to update post');
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Edit Post</h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2">Title</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-2">Author</label>
          <input
            type="text"
            name="author"
            value={formData.author}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-2">Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-2">Slug</label>
          <input
            type="text"
            name="slug"
            value={formData.slug}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-2">Cover Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleCoverImageChange}
            className="mb-2"
          />
          {preview && (
            <img src={preview} alt="Cover preview" className="max-h-40 object-cover" />
          )}
        </div>

        <div>
          <h2 className="text-xl font-bold mb-4">Content Blocks</h2>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="content-blocks">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-4"
                >
                  {formData.content.map((block, index) => (
                    <Draggable
                      key={index}
                      draggableId={`block-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="border p-4 rounded bg-white"
                        >
                          <div className="flex justify-between mb-2">
                            <div {...provided.dragHandleProps} className="cursor-move">
                              ⋮⋮
                            </div>
                            <button
                              type="button"
                              onClick={() => removeContentBlock(index)}
                              className="text-red-500"
                            >
                              Remove
                            </button>
                          </div>
                          
                          {block.type === 'text' ? (
                            <textarea
                              value={block.content}
                              onChange={(e) => handleTextChange(e.target.value, index)}
                              className="w-full p-2 border rounded min-h-[200px]"
                              placeholder="Enter your content here..."
                            />
                          ) : (
                            <div>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageUpload(e, index)}
                                className="mb-2"
                              />
                              {block.src && (
                                <img
                                  src={block.src}
                                  alt={`Content block ${index}`}
                                  className="max-h-40 object-cover"
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="mt-4 space-x-4">
            <button
              type="button"
              onClick={() => addContentBlock('text')}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Add Text Block
            </button>
            <button
              type="button"
              onClick={() => addContentBlock('image')}
              className="px-4 py-2 bg-green-500 text-white rounded"
            >
              Add Image Block
            </button>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/posts')}
            className="px-4 py-2 bg-gray-500 text-white rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            {loading ? 'Updating...' : 'Update Post'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPost;