import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

// Initial state with localStorage loading
const initialState = {
  cartState: false,
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [], // Load cart from localStorage
  cartTotalAmount: 0,
  cartTotalQantity: localStorage.getItem("totalQTY")
    ? JSON.parse(localStorage.getItem("totalQTY"))
    : 0, // Load total quantity from localStorage
  currency: 'USD',
  exchangeRates: localStorage.getItem("exchangeRates")
    ? JSON.parse(localStorage.getItem("exchangeRates"))
    : {},
};

export const fetchExchangeRates = createAsyncThunk(
  "cart/fetchExchangeRates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("https://api.exchangerate-api.com/v4/latest/USD");
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Ensure USD is always 1
      return { USD: 1, ...data.rates };
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      return rejectWithValue(error.message);
    }
  }
);

const CartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },

    extraReducers: (builder) => {
      builder
        .addCase(fetchExchangeRates.pending, (state) => {
          // Optionally handle loading state
        })
        .addCase(fetchExchangeRates.fulfilled, (state, action) => {
          state.exchangeRates = action.payload;
          localStorage.setItem("exchangeRates", JSON.stringify(action.payload));
        })
        .addCase(fetchExchangeRates.rejected, (state, action) => {
          console.error('Failed to fetch exchange rates:', action.payload);
          // Keep using the fallback rates from initialState
        });
    },

    setOpenCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },
    setCloseCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },

    setAddItemToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        // Update quantity if the item already exists in the cart
        state.cartItems[itemIndex].cartQuantity += action.payload.cartQuantity;
      } else {
        // Add new item to the cart
        const tempProduct = { ...action.payload, cartQuantity: action.payload.cartQuantity || 1 };
        state.cartItems.push(tempProduct);
      }
      state.totalQTY += action.payload.cartQuantity || 1;

      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setIncreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;
        state.totalQTY += 1;
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setDecreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0 && state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        state.totalQTY -= 1;
      } else if (itemIndex >= 0 && state.cartItems[itemIndex].cartQuantity === 1) {
        // If quantity is 1 and you decrease it, remove the item
        state.cartItems.splice(itemIndex, 1);
        state.totalQTY -= 1;
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setRemoveItemFromCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        state.totalQTY -= state.cartItems[itemIndex].cartQuantity; // Subtract the removed quantity from total
        state.cartItems.splice(itemIndex, 1);
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setClearCartItems: (state) => {
      state.cartItems = [];
      state.totalQTY = 0;

      // Clear from localStorage
      localStorage.removeItem("cartItems");
      localStorage.removeItem("totalQTY");
    },

    setGetTotals: (state, action) => {
      let { totalAmount, totalQTY } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const totalPrice = price * cartQuantity;

          cartTotal.totalAmount += totalPrice;
          cartTotal.totalQTY += cartQuantity;

          return cartTotal;
        },
        {
          totalAmount: 0,
          totalQTY: 0,
        }
      );

      state.cartTotalAmount = totalAmount;
      state.cartTotalQantity = totalQTY;

      // Save totals to localStorage (optional if you want to persist total amount as well)
      localStorage.setItem("cartTotalAmount", JSON.stringify(state.cartTotalAmount));
      localStorage.setItem("totalQTY", JSON.stringify(state.cartTotalQantity));
    },
  },
});

export const {
  setOpenCart,
  setCloseCart,
  setAddItemToCart,
  setRemoveItemFromCart,
  setIncreaseItemQTY,
  setDecreaseItemQTY,
  setClearCartItems,
  setGetTotals,
  setCurrency,
} = CartSlice.actions;

export const selectCartState = (state) => state.cart.cartState;
export const selectCartItems = (state) => state.cart.cartItems;
export const selectTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectTotalQTY = (state) => state.cart.cartTotalQantity;
export const selectExchangeRates = (state) => state.cart.exchangeRates;
export const selectCurrency = (state) => state.cart.currency;

export default CartSlice.reducer;
