import React from "react"
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FaShareAlt, FaFacebook, FaTwitter, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import axios from 'axios';
import Preloader from '../components/Pre';

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]); // To store comments
    const [newComment, setNewComment] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showShareOptions, setShowShareOptions] = useState(false);

    const toggleShareOptions = () => {
      setShowShareOptions(!showShareOptions);
    };


    useEffect(() => {
      const fetchPost = async () => {
        try {
          const response = await fetch(`https://sope-backend.vercel.app/post/${slug}`);
          // const response = await fetch(`http://localhost:4000/post/${slug}`);
          if (!response.ok) {
            throw new Error("Failed to fetch the post");
          }
          const data = await response.json();
          setPost(data);
          console.log("blog", data)
          setComments(data.comments || []); // Assuming the API provides comments in `data.comments`
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPost();
    }, [slug]);



    const handleCommentSubmit = async (e) => {
      e.preventDefault();

      // Validate required fields
      if (!name.trim() || !email.trim() || !newComment.trim()) {
        alert("Name, email, and comment text are required.");
        return;
      }

      try {
        // const response = await fetch(`http://localhost:4000/post/${slug}/comment`, {
        const response = await fetch(`https://sope-backend.vercel.app/post/${slug}/comment`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, email, text: newComment, website }),
        });

        if (response.ok) {
          const updatedComments = await response.json();
          setComments(updatedComments);
          setName("");
          setEmail(""); 
          setWebsite("");
          setNewComment("");
        } else {
          const errorData = await response.json();
          console.error("Error:", errorData.error);
          alert(errorData.error);
        }
      } catch (error) {
        console.error("Error adding comment:", error);
        alert("Failed to add comment. Please try again.");
      }
    };


    useEffect(() => {
      const fetchPostAndComments = async () => {
        try {
          // Fetch the blog post
          // const postResponse = await fetch(`http://localhost:4000/post/${slug}`);
          const postResponse = await fetch(`https://sope-backend.vercel.app/post/${slug}`);
          if (!postResponse.ok) {
            throw new Error("Failed to fetch the post");
          }
          const postData = await postResponse.json();
          setPost(postData);

          // Fetch comments for the post
          // const commentsResponse = await fetch(`http://localhost:4000/post/${slug}/comments`);
          const commentsResponse = await fetch(`https://sope-backend.vercel.app/post/${slug}/comments`);
          if (!commentsResponse.ok) {
            throw new Error("Failed to fetch comments");
          }
          const commentsData = await commentsResponse.json();
          setComments(commentsData);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPostAndComments();
    }, [slug]);

    const postUrl = encodeURIComponent(window.location.href);


    if (loading) {
      return <Preloader />;
    }
    if (error) return <p>Error: {error}</p>;


    
    return (
        <div className="bg-[#252525] flex justify-center items-center xl:px-5 min-h-screen">
          
            <div className="group xl:w-[60%] lg:w-[60%] md:w-[70%] w-full xl:px-6 px-4 xl:px-0 lg:px-0 md:px-0 py-5 transition-all duration-300 transform">
                <div className="flex flex-row items-center">
                    <div>
                      <h1 className="xl:text-3xl text-xl font-bold mb-4 uppercase text-white">{post.name}</h1>
                      <div className="mb-4 text-[#d5c9b4]">
                        <span className="capitalize text-[#d5c9b4]">{post.author}</span> |{' '}
                        {post.date}
                      </div>
                    </div>
                    {/*<div className="ml-auto flex items-center space-x-4">
                        <a 
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-white hover:text-blue-600 transition-colors duration-300"
                        >
                          <FaFacebook size={24} />
                        </a>
                        <a 
                          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(post.name)}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-white hover:text-blue-400 transition-colors duration-300"
                        >
                          <FaTwitter size={24} />
                        </a>
                    </div>*/}

                    <div className="absolute top-3 right-3">
                      <button 
                        onClick={toggleShareOptions} 
                        className="p-2 bg-[#373333] text-white rounded-full hover:bg-[#252525] transition-colors duration-300"
                      >
                        <FaShareAlt size={20} />
                      </button>

                      {showShareOptions && (
                        <div className="absolute right-0 mt-2 w-48 bg-[#373333] shadow-lg rounded-lg p-2">
                          <a 
                            href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="flex items-center space-x-2 text-gray-800 hover:bg-gray-200 p-2 rounded-md"
                          >
                            <FaFacebook className="text-blue-600" /> <span className="text-white">Facebook</span>
                          </a>
                          <a 
                            href={`https://twitter.com/intent/tweet?url=${postUrl}&text=${post.name}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="flex items-center space-x-2 text-gray-800 hover:bg-gray-200 p-2 rounded-md"
                          >
                            <FaTwitter className="text-blue-400" /> <span className="text-white">Twitter</span>
                          </a>
                          <a 
                            href={`https://api.whatsapp.com/send?text=${post.name} ${postUrl}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="flex items-center space-x-2 text-gray-800 hover:bg-gray-200 p-2 rounded-md"
                          >
                            <FaWhatsapp className="text-green-500" /> <span className="text-white">WhatsApp</span>
                          </a>
                          <a 
                            href={`mailto:?subject=${post.name}&body=${postUrl}`} 
                            className="flex items-center space-x-2 text-gray-800 hover:bg-gray-200 p-2 rounded-md"
                          >
                            <FaEnvelope className="text-red-600" /> <span className="text-white">Email</span>
                          </a>
                        </div>
                      )}
                    </div>

                </div>
                <div>
                  <div>
                    {post.content.map((block, index) => {
                      // Check if block has a 'content' property or if the text is directly in 'text'
                      const text = block.content || block.text;
                      
                      if (text) {
                        return (
                          <p key={index} className="mb-4 text-md text-[#d5c9b4]">
                            {text}
                          </p>
                        );
                      }
                      
                      if (block.src) {
                        return (
                          <div key={index} className="mb-4">
                            <img
                              src={block.src}
                              alt={`Blog Image ${index + 1}`}
                              className="w-full h-auto"
                            />
                            {block.caption && (
                              <p className="mt-2 text-sm text-center text-[#d5c9b4]"><i>{block.caption}</i></p>
                            )}
                          </div>
                        );
                      }

                      
                      
                      return null;
                    })}
                  </div>
                </div>

                {/* Comments Section */}
                <div className="mt-10">
                  <h2 className="text-2xl font-bold mb-4 text-white uppercase">add a comment</h2>
                  {comments.length > 0 ? (
                    comments.map((comment, index) => (
                      <div key={index} className="mb-4 p-4 bg-[#1e1e1e] text-white rounded-lg">
                        <p className="font-bold"><span className="capitalize">{comment.name}</span> ({comment.email})</p>
                        <p>{comment.text}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-[#d5c9b4]">No comments yet. Be the first to comment!</p>
                  )}

                  {/* Add Comment Form */}
                  <form onSubmit={handleCommentSubmit} className="mt-6 w-full mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                      <div>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Full Name*"
                          className="w-full p-4 bg-[#1e1e1e] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#d5c9b4] mb-4"
                          required
                        />
                      </div>
                      <div>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email Address*"
                          className="w-full p-4 bg-[#1e1e1e] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#d5c9b4] mb-4"
                          required
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          placeholder="Website"
                          className="w-full p-4 bg-[#1e1e1e] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#d5c9b4] mb-4"
                        />
                      </div>
                    </div>
                    <textarea
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Add your comment..."
                      className="w-full p-4 bg-[#1e1e1e] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#d5c9b4] mb-4"
                      rows="4"
                      required
                    ></textarea>
                    <button
                      type="submit"
                      className="mt-4 px-6 py-2 bg-[#d5c9b4] text-black font-bold rounded-lg hover:bg-[#c4b09d] transition-colors duration-300"
                    >
                      Post Comment
                    </button>
                  </form>

                </div>

            </div>
        </div>
    );
};

export default BlogPost;
