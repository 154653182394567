import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import sope from "../../assets/bio.jpg"

import logo1 from '../../assets/dim-logo/1.png'
import logo2 from '../../assets/dim-logo/2.png'
import logo3 from '../../assets/dim-logo/3.png'
import logo4 from '../../assets/dim-logo/4.png'
import logo5 from '../../assets/dim-logo/5.png'
import logo6 from '../../assets/dim-logo/6.png'
import logo7 from '../../assets/dim-logo/7.png'
import logo8 from '../../assets/dim-logo/8.png'
import logo9 from '../../assets/dim-logo/9.png'
import logo10 from '../../assets/dim-logo/10.png'
import logo11 from '../../assets/dim-logo/11.png'
import logo12 from '../../assets/dim-logo/12.png'

import hoverLogo1 from '../../assets/color-logo/1.png'
import hoverLogo2 from '../../assets/color-logo/2.png'
import hoverLogo3 from '../../assets/color-logo/3.png'
import hoverLogo4 from '../../assets/color-logo/4.png'
import hoverLogo5 from '../../assets/color-logo/5.png'
import hoverLogo6 from '../../assets/color-logo/6.png'
import hoverLogo7 from '../../assets/color-logo/7.png'
import hoverLogo8 from '../../assets/color-logo/8.png'
import hoverLogo9 from '../../assets/color-logo/9.png'
import hoverLogo10 from '../../assets/color-logo/10.png'
import hoverLogo11 from '../../assets/color-logo/11.png'
import hoverLogo12 from '../../assets/color-logo/12.png'

import guardian from '../../assets/dim-logo/guardian.png'
import npr from '../../assets/dim-logo/npr.png'


const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
];

const hoverLogos = [
  hoverLogo1, // Replace with your hovered logos
  hoverLogo2,
  hoverLogo3,
  hoverLogo4,
  hoverLogo5,
  hoverLogo6,
  hoverLogo7,
  hoverLogo8,
  hoverLogo9,
  hoverLogo10,
  hoverLogo11,
  hoverLogo12,
];

const links = [
  'https://www.bbc.com',
  'https://www.gavi.com',
  'https://www.npr.org',
  'https://www.undp.org/nigeria',
  'https://www.unicef.org',
  'https://cerf.un.org/countries/africa/nigeria',
  'https://www.recreateafrica.org',
  'https://eggcorndigital.com',
  'https://miva.university',
  'https://company10.com',
  'https://www.thisdaystyle.ng.com',
  'https://www.mikano.com.com',
];


const Bio = () => {
	
	const [hoveredIndex, setHoveredIndex] = useState(null);
  const [honorsData, setHonorsData] = useState([])
  const [publicationsData, setPublicationsData] = useState([])
  const [pressData, setPressData] = useState([])
  const [content, setContent] = useState("");



  


  // Fetch abouttext when component mounts
  const fetchAboutText = async () => {
    try {
      // const response = await fetch("http://localhost:4000/abouttext");
      const response = await fetch("https://sope-backend.vercel.app/abouttext");

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Content:", data);

        // Ensure we get the correct content format
        if (data.about && data.about.content) {
          setContent(data.about.content);
        } else {
          console.error("No content available");
        }
      } else {
        console.error("Error fetching about text:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching about text:", error);
    }
  };


  // HONORS
  const fetchHonorsData = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/honors'); // Replace with your backend endpoint
      // const response = await fetch('http://localhost:4000/honors');
        if (response.ok) {
          const data = await response.json();
          setHonorsData(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };


  // PUBLICATIONS
  const fetchPublicationsData = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/publications'); // Replace with your backend endpoint
      // const response = await fetch('http://localhost:4000/publications');
        if (response.ok) {
          const data = await response.json();
          setPublicationsData(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  // PRESS
  const fetchPress = async () => {
    try {
      const response = await fetch('https://sope-backend.vercel.app/client'); // Replace with your backend endpoint
      // const response = await fetch('http://localhost:4000/client');
        if (response.ok) {
          const data = await response.json();
          setPressData(data.press);
          console.log(data.press)
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };




useEffect(() => {
    // Fetch data from the backend
    fetchHonorsData();
    fetchPublicationsData();
    fetchPress();
    fetchAboutText();
  }, []);


	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			{/*<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 px-4 py-4 bg-[#252525]">
				
			</div>*/}

			<div className="grid md:grid-cols-2 grid-cols-1 xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525] gap-8">
				<div className="py-4 mb-6 order-2 md:order-1">
					<div className="border-b border-[#484540] py-4 mb-6">
						<p
							className="uppercase text-xl text-[#e7ddd3] font-semibold tracking-[.15em]"
						>
							about me
						</p>
					</div>
					<div>
            {/*<ReactQuill
              className="text-[#b7a78b] text-[13px] custom-quill p-4" 
              value={content}
              readOnly={true} // Makes it non-editable
              theme="bubble" // Uses a minimal theme for display
            />*/}
						<p className="mb-4 text-[#b7a78b] text-[15px]">
							Sope Adelaja is a Nigerian-born creative professional with over 7-year-track record of expression in photography
							and film who has evolved into becoming a household name of repute. Sope, from a young age has constantly found himself
							caught between dreams and ideas. Inspired by life and his experiences, Sope’s signature lifestyle/portraiture style
							of photography forced him into a quest of deeper appreciation while the capturing of core human anthropologic expression.
						</p>
						<p className="mb-4 text-[#b7a78b] text-[15px]">
							From the age of 18, Sope began using photography as a tool for self-expression, portraying profound thoughts that are
							often left unspoken into visual narratives. His curiosity about different ways of living has brought him to new and
							unfamiliar territories, including targeted areas in North-eastern Nigeria. During a trip undertaken for the United
							Nations Development Programme (UNDP), he turned his lens on Internally Displaced Persons (IDPs) affected by the Boko
							Haram insurgency and conflicts involving Non-State Armed Groups (NSAG), which served as a catalyst for ongoing interventions.
							With his unique approach, the Nigerian-born multidisciplinary artist has been consistently sought after by prominent bodies
							and notable organizations, including NPR for their 2024 Global Food Crisis Report, BBC Africa, GAVI, the United Nations
							International Children’s Fund (UNICEF), and many others.
						</p>
						<p className="text-[#b7a78b] text-[15px]">
							As a firm believer in the transformational power of capturing moments in still images and cinematography, Sope is passionate
							about catalyzing change deeply-rooted in cultural practices across the globe. He also works on commissioned and commercial
							projects with select brands and bodies.
						</p>
					</div>
				</div>
				<div className="flex items-center justify-center order-1 md:order-2">
					<img
						src={sope}
						alt="Sope Adelaja"
						className="rounded-full w-[20em] h-[20em] sm:w-[20em] sm:h-[20em] md:w-60 md:h-60 lg:w-[30em] lg:h-[30em] object-cover"
					/>
				</div>
			</div>


			<div className="flex flex-col xl:flex-row xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525] gap-8">

  {/* Left Column (Client, Publications, Honors) */}
  <div className="flex flex-col flex-1 gap-8 order-2 xl:order-1">
    {/* Client Section */}
    <div>
      <div className="border-b border-[#484540] py-4 mb-6">
        <p
          className="uppercase text-xl text-[#e7ddd3] font-semibold tracking-[.15em]"
        >
          client
        </p>
      </div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4 p-4">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="flex items-center justify-center 
              xl:w-[150px] xl:h-[150px] lg:w-[120px] lg:h-[140px] 
              md:w-[110px] md:h-[130px] 
              w-[100px] h-[120px] 
              bg-[#939292] hover:bg-[#ccc] transition-colors duration-300 cursor-pointer shadow-md mx-auto 
              relative"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <a href={links[index]} target="_blank" rel="noopener noreferrer" className="w-full h-full">
              <img
                src={logo}
                alt={`logo-${index + 1}`}
                className="object-contain w-full h-full absolute top-0 left-0 transition-opacity duration-300"
                style={{
                  opacity: hoveredIndex === index ? 0 : 1
                }}
              />
              <img
                src={hoverLogos[index]}
                alt={`hover-logo-${index + 1}`}
                className="object-contain w-full h-full absolute top-0 left-0 transition-opacity duration-300"
                style={{
                  opacity: hoveredIndex === index ? 1 : 0
                }}
              />
            </a>
          </div>
        ))}
      </div>
    </div>

    {/* Publications Section */}
    <div>
      <div className="border-b border-[#484540] py-4 mb-2">
        <h2
          className="uppercase text-[#e7ddd3] text-xl font-semibold tracking-[.15em]"
        >
          publications
        </h2>
      </div>
      {publicationsData.length > 0 && publicationsData.map((publications, index) => (
      <div className="py-2" key={publications._id}>
        {/* Publication Entries */}
        <p className="text-[#efefcb] text-[15px]">
          {publications.publicationName}<i>{publications.publicationNames}</i>
          <a
            href={publications.publicationLink}
            target="_blank"
            rel="noreferrer"
            className="text-[#efefcb] underline decoration-current capitalize ml-2"
          >
            link
          </a>
        </p>
      </div>
      ))}
    </div>

    {/* Honors Section */}
    <div>
      <div className="border-b border-[#484540] py-4 mb-2">
        <h2
          className="uppercase text-[#e7ddd3] text-xl font-semibold tracking-[.15em]"
        >
          honors
        </h2>
      </div>
      {honorsData.length > 0 && honorsData.map((honors, index) => (
      <div className="py-2" key={honors._id}>
        <p className="text-[#efefcb] text-[15px]">
          {honors.honorsName} <i>{honors.honorsNames}</i>
          <a
            href={honors.honorsLink}
            target="_blank"
            rel="noreferrer"
            className="text-[#efefcb] underline decoration-current capitalize ml-2"
          >
            link
          </a>
        </p>
      </div>
      ))}
    </div>
  </div>

  {/* Right Column (Recent Press) */}
  <div className="flex flex-col w-full lg:w-[300px] mx-auto bg-[#252525] gap-4 order-1 xl:order-2">
    <div className="border-b border-[#484540] py-4 mb-2">
      <h2
        className="uppercase text-[#e7ddd3] text-xl font-semibold tracking-[.15em]"
      >
        recent press
      </h2>
    </div>
    {pressData.length > 0 && pressData.map((press, index) => (
    <div className="py-4">
      {/* Press Entries */}
      <img className="h-[100px] mb-4" src={press.cover} alt="header" />
      <p className="text-[#b7a78b] text-[15px] mb-4">
        {press.name}
      </p>
      <a
        href={press.link}
        target="_blank"
        rel="noreferrer"
        className="border border-[#484540] px-4 py-2 text-[13px] text-[#efefcb] uppercase"
      >
        read more
      </a>
    </div>
    ))}
  </div>
</div>


		</>
	)
}

export default Bio
