// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyAcSF3LfQ-7P9ibTXGPwjf1KhgqPo6EDAo",
//   authDomain: "sopeadelaja-f857f.firebaseapp.com",
//   projectId: "sopeadelaja-f857f",
//   // storageBucket: "sopeadelaja-f857f.firebasestorage.app",
//   storageBucket: "sopeadelaja-f857f.appspot.com",
//   messagingSenderId: "938158460420",
//   appId: "1:938158460420:web:cf650aa14546f9b4f76f51",
//   measurementId: "G-T5ZHCJTLXH"
// };

// const app = initializeApp(firebaseConfig);
// export default app;



// firebase.js
import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAcSF3LfQ-7P9ibTXGPwjf1KhgqPo6EDAo",
  authDomain: "sopeadelaja-f857f.firebaseapp.com",
  projectId: "sopeadelaja-f857f",
  storageBucket: "sopeadelaja-f857f.appspot.com", // Corrected storage bucket
  messagingSenderId: "938158460420",
  appId: "1:938158460420:web:cf650aa14546f9b4f76f51",
  measurementId: "G-T5ZHCJTLXH"
};

// Ensure Firebase is initialized only once
const app = getApps().length ? getApps()[0] : initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
