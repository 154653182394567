// AccountSummary.js
import { useState } from 'react';

const AccountSummary = ({ editName, isEditing, setIsEditing, setEditName, userData, handleUpdateName }) => {
  return (

    <div className="space-y-6">
      <div className="bg-[#252525] p-6 rounded-lg shadow">
        <h3 className="text-lg font-medium text-white mb-4">Personal Information</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white">Name</label>
            <div className="mt-1 flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-x-2 sm:space-y-0">
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={editName}
                    placeholder="enter name"
                    onChange={(e) => setEditName(e.target.value)}
                    className="capitalize shadow-sm text-black focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300"
                  />
                  <button
                    onClick={handleUpdateName}
                    className="inline-flex items-center p-4 border border-transparent text-sm leading-4 font-medium text-white bg-[#b7a78b] hover:bg-[#d0c3a6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setIsEditing(false)}
                    className="inline-flex items-center p-4 border border-gray-300 text-sm leading-4 font-medium text-black bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <span className="text-white">{userData.name}</span>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium text-black bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Email</label>
            <div className="mt-1">
              <span className="text-white">{userData.email}</span>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Wallet Balance</label>
            <div className="mt-1">
              <span className="text-white">${userData.wallet || '0'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  );
};

export default AccountSummary;
